import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {
  Pagination, Scrollbar, A11y,
} from 'swiper/modules';
import { MdArrowCircleRight } from 'react-icons/md';
import { recipeCreatedData } from '@/lib/recipe-data';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import SwiperNavButtons from './SwiperNavButtons';

const RecipeCreatedSlider = () => {
  const router = useRouter();
  const handleSeeMore = (id: string) => {
    router.push(`/recipe/${id}`);
  };
  return (
    <Swiper
      className=""
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 18,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 18,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      }}
      modules={[Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
    >
      {recipeCreatedData.map((slide: { id: any; image?: any; title?: any; }) => {
        const {
          id, image, title,
        } = slide;
        return (
          <SwiperSlide
            key={slide.id}
            className="rounded-xl min-h-[240px] bg-slate-100 text-deep-blue font-lato"
          >
            <div className="flex flex-col relative lg:items-center lg:space-x-4">
              <div className="relative w-full h-full">

                <Image
                  className="mb-3 w-full h-full object-cover  rounded-t-lg brightness-50 md:mb-0"
                  src={image}
                  alt={title}
                  width={500}
                  height={500}
                />
                <div className="font-bold  absolute inset-0 flex items-center justify-center text-center pb-1">
                  <h2 className="text-transparent bg-clip-text bg-gradient-to-r from-green to-white shadow-xl text-lg md:text-xl lg:text-2xl font-bold">
                    {title}
                  </h2>
                </div>
              </div>
              <button
                type="button"
                className="flex items-center gap-1 justify-center p-3 bg-transparent
            font-bold text-deep-blue hover:text-blue cursor-pointer"
                onClick={() => handleSeeMore(id)}
              >
                See More
                <MdArrowCircleRight className="-mb-1" />
              </button>
            </div>
          </SwiperSlide>

        );
      })}
      <SwiperNavButtons />
    </Swiper>
  );
};
const RecipesCreated = () => (
  <div className="font-lato bg-gray-200 rounded-lg">
    <div className="bg-white max-h-[400px] p-12 rounded-lg">
      <div className="text-center text-gray-800">
        <h2
          className="text-3xl font-bold mb-8  font-lato text-[#D5006D] section-heading"
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-delay="1600"
        >
          Recipes Created by Others
        </h2>
      </div>
      <div
        data-aos="fade-up"
        data-aos-offset="300"
        data-aos-delay="2000"
      >
        <RecipeCreatedSlider />
      </div>
    </div>
  </div>
);
export default RecipesCreated;
