'use client';

import React from 'react';
import Benifits from '@/components/landing/Benefits';
import Features from '@/components/landing/Features';
import RecipesCreated from '@/components/landing/RecipeCreated';
import Testimonial from '@/components/landing/Testimonials';
import Hero from '@/components/landing/hero';
import PricingPlan from '@/components/subscription-plan';

const LandingPage = () => (
  <>
    {/* Hero Section */}
    <div className="">
      <section className="">
        <Hero />
      </section>
      <section id="benifits" className="bg-gray-100 py-16 rounded-xl flex justify-center mb-24 lg:mb-32">
        <Benifits />
      </section>
      {/* Features Section */}
      <section id="features" className="mb-24 lg:mb-32">
        <Features />
      </section>
      {/* Testimonials  Section */}
      <section id="testimonial" className="lg:pb-16 pb-64 mb-24 lg:mb-32">
        <Testimonial />
      </section>
      {/* Price Plan Section */}
      <section id="price_plan" className="bg-gray-200 flex rounded-xl justify-center  mb-24 lg:mb-32">
        <PricingPlan />
      </section>
      {/* RecipesCreated  Section */}
      <section id="recipe_created" className="bg-gray-100  rounded-xl ">
        <RecipesCreated />
      </section>
    </div>
  </>
);
export default LandingPage;
