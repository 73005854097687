'use client';

import { useAuth } from '@clerk/nextjs';
import TypewriterComponent from 'typewriter-effect';
import React, { useState } from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import Head from 'next/head';
import Loading from '../loading';
import { Button } from '../ui/button';

const Hero = () => {
  const [loading, setLoading] = useState(false);
  const { isSignedIn } = useAuth();
  const router = useRouter();
  const handleClick = async () => {
    setLoading(true);
    const destination = isSignedIn ? '/dashboard' : '/sign-up';
    router.push(destination);
  };

  return (
    <>
      <Head>
        <link rel="preload" href="/openartrobo.webp" as="image" />
        <link rel="preload" href="/openart.webp" as="image" />
        <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600&display=swap" rel="stylesheet" />
      </Head>
      <div className="mt-12 lg:mt-20 py-20 text-center ">
        <div>
          <h1 className="text-4xl lg:text-5xl
           font-extrabold font-lato bg-gradient-to-r from-green to-white
           font-poppins text-transparent bg-clip-text"
          >
            Discover
            {' '}
            <span className="hidden lg:inline-block">Delicious </span>
&nbsp;Recipes with AI
          </h1>
          <p className="text-sm lg:text-base text-gray-300 mt-4 font-lato pl-6">Let CookGPT help you find and create amazing dishes and cocktail effortlessly.</p>
          <div className="font-extrabold text-xl mb-4 mt-4 text-transparent bg-clip-text bg-gradient-to-r from-green to-white">
            <TypewriterComponent
              options={{
                strings: [
                  'Food Recipe Generation',
                  'Cocktail Recipe Generation',
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </div>

          <Button
            className="mt-6 bg-[#D5006D] font-semibold lg:mt-0 text-white w-full lg:w-[400px]
          text-lg h-full hover:bg-pink-600 hover:font-bold hover:transition-all duration-300"
            onClick={handleClick}
          >
            {loading ? <Loading loadingtext="Starting" /> : 'Start Generating For Free'}
          </Button>
          <p className="text-gray-300 text-xl capitalize mt-1">
            {' '}
            Join over 700,000+ users discovering delicious cuisine with ChookGPT!
            !
          </p>
        </div>
      </div>
      <section className="mb-24 lg:mb-32 -mt-12 lg:mt-0">
        <div className="text-center lg:flex items-center w-full">
          <div className="z-10 lg:max-w-4xl lg:max-h-lg lg:shadow-md lg:shadow-white py-6">
            <p className="text-green text-3xl lg:text-4xl mt-4 font-lato">Become a Master Chef with the Power of AI.</p>
            <p className="text-gray-200 px-10 pt-6 text-lg font-lato text-center">Whether you are a novice cook or an experienced chef, CookGPT &apos;s intelligent guidance helps you create delicious, restaurant-quality dishes with ease.</p>
          </div>
          <div className="relative max-w-lg aspect-w-1 aspect-h-1">

            <Image
              src="/openart.webp"
              alt="Delicious Food robot"
              width={500}
              height={500}
              sizes="(max-width: 768px) 100vw, (max-width: 641px) 50vw, 33vw"
              priority
              className="lg:hidden mx-auto rounded-lg mt-6"
            />
            <Image
              src="/openartrobo.webp"
              width="750"
              height="376"
              sizes="(max-width: 768px) 100vw, (max-width: 641px) 50vw, 33vw"
              alt="Delicious Food"
              priority
              className="z-20 max-w-lg   rounded-tr-lg rounded-br-lg  mt-6 lg:mt-0 hidden lg:block"
            />
          </div>
        </div>
      </section>
    </>
  );
};
export default Hero;
