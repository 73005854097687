import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {
  Pagination, Scrollbar, A11y,
} from 'swiper/modules';
import slider from '@/lib/slider-data';
import Image from 'next/image';
import SwiperNavButtons from './SwiperNavButtons';

const TestimonialSlider = () => (
  <Swiper
    className=""
    breakpoints={{
      320: {
        slidesPerView: 1,
        spaceBetween: 18,
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 18,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    }}
    modules={[Pagination, Scrollbar, A11y]}
    spaceBetween={50}
    slidesPerView={1}
  >
    {slider.map((slide) => {
      const {
        message, image, name, email,
      } = slide;
      return (
        <SwiperSlide
          key={slide.id}
          className="rounded-xl p-4 lg:p-6 min-h-[240px] bgc-color text-gray-100 font-lato"
        >
          <p className="mb-8 min-h-[100px] text-[15px] ">{message}</p>
          <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-4">
            <div className="w-14">
              <Image className="mb-3 md:mb-0 rounded-full" width={100} height={100} src={image} alt="" />
            </div>
            <div>
              <div className="font-medium text-base">{name}</div>
              <div className="font-medium text-green">{email}</div>
            </div>
          </div>
        </SwiperSlide>
      );
    })}
    <SwiperNavButtons />
  </Swiper>
);

const Testimonial = () => (

  <div className="font-lato bg-gray-200 rounded-lg">
    <div className="bg-white max-h-[400px] p-12 rounded-lg">
      <div className="text-center text-gray-800">
        <h2
          className="text-3xl font-bold mb-8 pt-4 font-lato text-[#D5006D] section-heading"
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-delay="1600"
        >
          What our clients say
        </h2>
        <p
          className="max-w-2xl mx-auto mb-12 "
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-delay="1800"
        >
          At CookGPT, our clients rave about the transformative impact of our platform on their
          cooking experiences. Here is a glimpse of what they have said!
        </p>
      </div>
      <div
        data-aos="fade-up"
        data-aos-offset="300"
        data-aos-delay="2000"
      >
        <TestimonialSlider />
      </div>
    </div>
  </div>
);

export default Testimonial;
