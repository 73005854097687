const slider = [
  {
    id: 1,
    message:
          '"CookGPT has revolutionized my home cooking with personalized recipe suggestions and the ability to remix dishes. It is a family favorite."',
    image: '/avt5.webp',
    name: 'Laurie Howell',
    email: 'Aspiring Chef',
  },
  {
    id: 2,
    message:
          ' "The idea generator tool saves me so much time. CookGPT fits perfectly into my busy lifestyle, making meal planning a breeze."',
    image: '/avt2.webp',

    name: 'David Lee',
    email: 'Busy Professional',
  },
  {
    id: 3,
    message:
          '"CookGPT is an invaluable resource for refining recipes and visualizing dishes. The Pro plan features are fantastic for my catering business."',
    image: '/avt_english_women.webp',

    name: 'Emily Roberts',
    email: 'Home Cook Enthusiast',
  },
  {
    id: 4,
    message:
          '"CookGPT simplifies meal planning and keeps track of my favorite healthy recipes. The ad-free experience is a huge plus!"',
    image: '/avt_american.webp',

    name: 'Alex Martinez',
    email: 'Health-Conscious Foodie',
  },
  {
    id: 5,
    message:
          '"CookGPT has elevated my food blog by providing endless inspiration and helping me create unique recipes. The AI personalization is a game-changer!"',
    image: '/avt6_p.webp',

    name: 'Nuno Borges',
    email: 'Culinary Blogger',
  },
  {
    id: 6,
    message:
          '"With a kid and a hectic schedule, CookGPT is my go-to for quick and healthy meal ideas. The subscription is worth every penny for the peace of mind it brings to my daily routine."',
    image: '/avt_latino.webp',

    name: 'Maria Gonzalez',
    email: 'Busy Mom',
  },
];
export default slider;
