export const recipes = [
  {
    id: '16f6ede0-52e9-4643-8d84-11dd7150bae5',
    title: 'Salmon Sushi Rolls',
    description: 'a classic favorite in Japanese cuisine',
    image: '/salmon_sushi_rolls.webp',

    ingredients: ['2 cups sushi rice', '3 cups water', '1/3 cup rice vinegar', '2 tablespoons sugar', '1 teaspoon salt', '4 nori (seaweed) sheets', '8 ounces fresh salmon, thinly sliced', 'Soy sauce, for dipping', 'Wasabi and pickled ginger, for serving'],

    instructions: ['Rinse the sushi rice under cold water until the water runs clear.', "Combine the rice and water in a rice cooker and cook according to the manufacturer's instructions.", 'In a small saucepan, combine the rice vinegar, sugar, and salt. Heat over low heat, stirring until the sugar and salt have dissolved.', 'Transfer the cooked rice to a large bowl and gently fold in the vinegar mixture. Allow the rice to cool to room temperature.', 'Place a sheet of nori on a bamboo sushi mat. Spread a thin layer of rice over the nori, leaving a small border at the top edge.', 'Place a few slices of salmon along the bottom edge of the rice.', 'Roll the sushi tightly from the bottom, using the bamboo mat to help shape it. Seal the roll by moistening the top edge of the nori with a little water.', 'Slice the roll into bite-sized pieces using a sharp knife. Repeat with the remaining ingredients.', 'Serve the sushi rolls with soy sauce, wasabi, and pickled ginger.'],

  },
  {
    id: '468ca109-a4d2-44ef-ac69-282c71821a09',
    title: 'Italian Espresso Affogato',
    image: '/italian_espresso.webp',
    ingredients: ['1 shot of espresso coffee', '1 scoop of vanilla ice cream'],
    instructions: ['1. Brew a strong shot of espresso using an espresso machine or a Moka pot', '2. While the espresso is brewing, scoop a generous portion of vanilla ice cream into a serving glass or cup.', '3. Pour the hot espresso over the vanilla ice cream.', '4. Allow the espresso to melt the ice cream slightly, creating a delicious creamy texture.', 'Serve immediately and enjoy the rich and indulgent flavor combination of the espresso and vanilla ice cream in this classic Italian dessert, known as Affogato.'],
  },
  {
    id: 'bdd55956-0035-475b-9b31-ec061bde39b3',
    title: 'Authentic Neapolitan Pizza',
    image: '/authentic_nepolitan.webp',
    ingredients: ['2 1/4 cups (300g) Italian 00 flour', '1/2 teaspoon (2g) salt', '1/4 teaspoon (1g) active dry yeast', '2/3 cup (160ml) lukewarm water', '1/2 cup (120g) San Marzano tomatoes, crushed', '5 oz (150g) fresh mozzarella cheese, drained and torn into pieces', ' Fresh basil leaves', 'Extra-virgin olive oil'],
    instructions: ["1. In a large bowl, mix flour and salt together. In a separate small bowl, dissolve the yeast in lukewarm water. Let it sit for a few minutes until it's foamy.", '2. Make a well in the center of the flour mixture and pour in the yeast mixture. Using a wooden spoon, mix until a dough forms.', '3. Turn the dough out onto a lightly floured surface and knead for about 10 minutes, until smooth and elastic. Shape it into a ball and place in a lightly oiled bowl. Cover with a damp cloth and let it rise in a warm place for about 1.5 to 2 hours, or until doubled in size.', '4. Preheat your oven to its highest setting, ideally reaching 500-550°F (260-290°C). If you have a pizza stone, place it in the oven to heat up.', '5. Punch down the dough and divide it into two equal portions. On a lightly floured surface, shape each portion into a round pizza base.', '6. Spread the crushed tomatoes over the pizza bases, leaving a border around the edges. Top with torn mozzarella cheese and scatter some fresh basil leaves on top.', '7. Drizzle with a little olive oil and slide the pizzas onto the preheated pizza stone or baking tray. Bake in the oven for about 8-10 minutes, or until the crust is golden and the cheese is bubbly.', '8. Remove from the oven, slice, and serve hot. Buon appetito!', 'Enjoy your delicious homemade Neapolitan Pizza!'],
  },
  {
    id: '065f1162-0b8f-4606-b44f-0c4d432e128a',
    title: 'Authentic Neapolitan Pizza',
    image: '/chicken_tikka.webp',
    ingredients: ['1 lb chicken breast, cut into bite-sized pieces', '1 cup plain yogurt', '1 tablespoon ginger garlic paste', '1 tablespoon garam masala', '1 tablespoon ground cumin', '1 tablespoon ground coriander', '1 teaspoon turmeric', '1 teaspoon chili powder (adjust to taste)', '1 teaspoon salt', '2 tablespoons oil', '1 onion, finely chopped', '1 cup heavy cream', 'Fresh cilantro, for garnish', 'Cooked basmati rice, to serve'],
    instructions: ['1. In a bowl, mix together yogurt, ginger garlic paste, garam masala, cumin, coriander, turmeric, chili powder, and salt. Add the chicken pieces, coating them well with the marinade. Cover and refrigerate for at least 2 hours, or ideally overnight.', '2. Heat oil in a large pan over medium-high heat. Add the chopped onion and cook until softened and translucent.', '3. Add the marinated chicken to the pan and cook until browned on all sides.', '4. Pour in the tomato puree and stir well. Lower the heat, cover, and let it simmer for about 15-20 minutes, or until the chicken is fully cooked through.', '5. Stir in the heavy cream and let it simmer for another 5 minutes, allowing the flavors to meld together.', '6. Garnish with fresh cilantro and serve hot over cooked basmati rice.', 'Enjoy your flavorful and aromatic Indian Chicken Tikka Masala!'],
  },
  {
    id: '31112966-0b30-4b11-817f-d0c6076aaa0e',
    title: 'Caprese Salad',
    image: '/salad.webp',
    ingredients: ['2 large ripe tomatoes', '1 bunch of fresh basil leaves', ' 1 ball of fresh mozzarella cheese', 'Extra virgin olive oil', 'Balsamic glaze (optional)', 'Salt and pepper to taste'],
    instructions: ['1. Wash the tomatoes and basil leaves thoroughly under running water. Pat them dry with a paper towel.', '2. Slice the tomatoes and mozzarella cheese into 1/4 inch thick slices.', '3. Arrange the tomato slices on a serving plate, alternating with mozzarella slices.', '4. Take the fresh basil leaves and place them on top of each tomato and mozzarella slice.', 'S5. Drizzle some extra virgin olive oil over the salad.', '6. Optional: drizzle some balsamic glaze over the salad for extra flavor.', '7. Season with salt and pepper to taste.', '8. Serve the Caprese salad immediately as a delicious appetizer or side dish.', 'Enjoy your fresh and flavorful Caprese Salad!'],
  },
  {
    id: 'a96746b5-0748-4c5e-9acc-bee3378f28c9',
    title: 'Classic Cheeseburger',
    image: '/burger.webp',
    ingredients: ['1 pound ground beef', 'Salt and pepper to taste', '4 slices of cheddar cheese', '4 burger buns', ' Lettuce leaves', 'Sliced tomatoes', 'Sliced onions', ' Ketchup and mustard (optional)', 'Pickles (optional)'],
    instructions: ['1. Preheat your grill or stovetop pan over medium-high heat.    ', '2. Divide the ground beef into 4 equal portions and shape them into patties. Season each patty with salt and pepper.', '3. Place the patties on the grill or pan and cook for about 3-4 minutes on each side, or until they reach your desired level of doneness.', '4. Just before you flip the patties, top each one with a slice of cheddar cheese to melt.', '5. While the patties are cooking, lightly toast the burger buns on the grill or in a separate pan.', '6. Assemble the burgers by placing a lettuce leaf on the bottom half of each bun, followed by a cooked patty with melted cheese', 'Add sliced tomatoes and onions on top of the cheese', '8. If desired, add ketchup, mustard, and pickles on top of the onions.', '9. Place the top half of the burger bun on the fillings to complete the cheeseburger.', '10. Serve hot with your favorite side dishes and enjoy your classic cheeseburger!'],
  },
];
export const recipeCreatedData = [
  {
    id: recipes[0].id,
    title: 'Salmon Sushi Rolls',
    image: '/salmon_sushi_rolls.webp',
  },
  {
    id: recipes[1].id,
    title: 'Espresso Affogato',
    image: '/italian_espresso.webp',
  },
  {
    id: recipes[2].id,
    title: 'Neapolitan Pizza',
    image: '/authentic_nepolitan.webp',
  },
  {
    id: recipes[3].id,
    title: 'Chicken Tikka Masala',
    image: '/chicken_tikka.webp',
  },
  {
    id: recipes[4].id,
    title: 'Caprese Salad',
    image: '/salad.webp',
  },
  {
    id: recipes[5].id,
    title: 'Classic Cheeseburger',
    image: '/burger.webp',
  },
];
