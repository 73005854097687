import React from 'react';
import { useSwiper } from 'swiper/react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

const SwiperNavButtons = () => {
  const swiper = useSwiper();

  return (
    <div className="swiper-nav-btns mt-2">
      <button
        onClick={() => swiper.slidePrev()}
        type="button"
        aria-label="Previous Slide"
        className="bg-blue hover:bg-deep-blue "
      >
        <IoIosArrowBack style={{ fontSize: '20px', color: '#fff' }} />
      </button>
      <button
        onClick={() => swiper.slideNext()}
        type="button"
        aria-label="Next Slide"
        className="bg-blue hover:bg-deep-blue"
      >
        <IoIosArrowForward style={{ fontSize: '20px', color: '#fff' }} />
      </button>
    </div>
  );
};
export default SwiperNavButtons;
