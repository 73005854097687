import React from 'react';
import Image from 'next/image';

const Features = () => (
  <div className="text-center  font-lato">
    <h2 className="text-2xl font-bold text-gray-200 section-heading  text-gray-200 ">Features</h2>
    <div className="mt-12 grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div className="flex items-center max-w-2xl p-4 bg-white rounded-lg shadow-lg hover:shadow-green">
        <Image
          src="/master_chef.webp"
          width={500}
          height={500}
          alt=""
          className="rounded-lg lg:w-1/2 w-1/3 h-full object-cover"
        />
        <div className="px-4 w-2/3">
          <h3 className="text-xl font-semibold text-deep-blue min-w-[120px]">Recipe Creation</h3>
          <p className="text-gray-600 mt-2 pb-1  text-gray-200">Get recipe suggestions based on your preferences and available ingredients with AI.</p>
        </div>
      </div>
      <div className="flex items-center max-w-2xl p-4  bg-white rounded-lg shadow-lg hover:shadow-green">
        <Image
          src="/macros.webp"
          width={500}
          height={500}
          alt=""
          className="rounded-lg lg:w-1/2 w-1/3 h-full object-cover"
        />
        <div className="px-4 w-2/3">
          <h3 className="text-xl font-semibold text-deep-blue min-w-[120px]">Master Chef</h3>
          <p className="text-gray-600 mt-2 pb-1 font-">Easy-to-follow instructions to help you cook like a professional master chef.</p>
        </div>
      </div>
      <div className="flex items-center max-w-2xl p-4  bg-white rounded-lg shadow-lg hover:shadow-green">
        <Image
          src="/mixo.webp"
          width={500}
          height={500}
          alt=""
          className="rounded-lg lg:w-1/2 w-1/3 h-full object-cover"
        />
        <div className="px-4 w-2/3">
          <h3 className="text-xl font-semibold text-deep-blue">Mixology</h3>
          <p className="text-gray-600 mt-2 pb-1">
            Generate unique cocktail recipes tailored to your preferences.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Features;
