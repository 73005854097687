import React from 'react';
import Image from 'next/image';

const Benifits = () => (
  <div className="text-center font-poppins">
    <h2 className="text-3xl font-bold text-[#D5006D] font-lato section-heading">Our Benefits</h2>
    <div className="mt-8 w-full grid lg:grid-cols-3 sm:grid-cols-2">
      <div className="max-w-xs min-h-[300px] p-4 m-4 bg-white rounded-lg shadow-lg hover:shadow-green relative">
        <h3 className="text-xl font-semibold text-deep-blue">AI-Powered Suggestions</h3>
        <p className="text-gray-600 mt-2 pb-1 text-[1.08rem]">Get recipe suggestions based on your preferences and available ingredients.</p>
        <div className="relative w-full h-64">
          <Image
            src="/openart2.webp"
            fill
            sizes="(max-width: 640px) 100vw, (max-width: 1200px) 50vw, 33vw"
            quality={75}
            alt="AI-Powered Suggestions"
            className="rounded-lg object-cover"
          />
        </div>
      </div>

      <div className="max-w-xs min-h-[300px] p-4 m-4 bg-white rounded-lg shadow-lg hover:shadow-green relative">
        <h3 className="text-xl font-semibold text-deep-blue">Step-by-Step Instructions</h3>
        <p className="text-gray-600 mt-2 pb-1 text-[1.08rem]">Easy-to-follow instructions to help you cook like a professional master chef.</p>
        <div className="relative w-full h-64">
          <Image
            src="/openart1.webp"
            fill
            sizes="(max-width: 640px) 100vw, (max-width: 1200px) 50vw, 33vw"
            quality={75}
            alt="Step-by-Step Instructions"
            className="rounded-lg object-cover"
          />
        </div>
      </div>

      <div className="max-w-xs min-h-[300px] p-4 m-4 bg-white rounded-lg shadow-lg hover:shadow-green relative">
        <h3 className="text-xl font-semibold text-deep-blue">Nutritional Information</h3>
        <p className="text-gray-600 mt-2 pb-1 text-[1.08rem]">Get detailed nutritional info for each recipe to stay on track with your health goals.</p>
        <div className="relative w-full h-64">
          <Image
            src="/openart3.webp"
            fill
            sizes="(max-width: 640px) 100vw, (max-width: 1200px) 50vw, 33vw"
            quality={75}
            alt="Nutritional Information"
            className="rounded-lg object-cover"
          />
        </div>
      </div>

    </div>
  </div>
);

export default Benifits;
